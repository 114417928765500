/* eslint-disable @typescript-eslint/camelcase */
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import React, { useState, useEffect, useContext } from 'react';
import CenterContent from '@components/atoms/center-content';
import Loading from '@molecules/loading';
import * as colors from '@data/colors';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Global from '../components/global-styles';

const Button = styled.button`
  background: ${colors.midGray};
  border-radius: 0.25rem;
  border: 1px solid ${colors.cyan};
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0.5rem 1rem;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
`;

// User data.
export interface User {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  picture: string;
}

// Interface for the AuthContext context object.
export interface AuthContext {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
  loading: boolean;
  popupOpen: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

// Context object, with useAuth hook for convenience.
export const AuthContext = React.createContext<AuthContext | null>(null);
export const useAuth = () => useContext(AuthContext);

// Settings for fetching data from Auth0. The audience value corresponds with
// the API configured in the Auth0 console, which is required for using the
// auth0-spa-js library with Hasura.
const AUTH0_OPTIONS = {
  scope: 'openid profile email',
  audience: 'hasura',
};

// Wrapper component to provide the auth context object to children. This is
// applied to the entire site via gatsby-browser.js and gatsby-ssr.js.
export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  // Track whether we are authenticated or not.
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Store the user object, once we have it.
  const [user, setUser] = useState(null as User | null);

  // Store the auth token to connect to Hasura, once we have it.
  const [token, setToken] = useState(null as string | null);

  // Store the Auth0 client, created in the useEffect hook, below.
  const [auth0Client, setAuth0] = useState(null as Auth0Client | null);

  // Track whether we are loading. This is true when the page first loads.
  // Once this becomes false, you can reliably use isAuthenticated to know
  // whether the user is logged in.
  const [loading, setLoading] = useState(true);

  // Whether or not the auth popup is open.
  const [popupOpen, setPopupOpen] = useState(false);

  // Once the user is authenticated, we need to fetch their token and profile
  // data from Auth0 to store in the user and token state variables. This is
  // called when the page first loads if the user was previosuly authenticated,
  // and also right after a user logs in.
  const setUserData = async (client: Auth0Client | null = auth0Client) => {
    // We can't do anything if there is no Auth0 client.
    if (!client) return;

    // Get the token and save it to state.
    const auth0Token = await client.getTokenSilently(AUTH0_OPTIONS);
    setToken(auth0Token);

    // Get the user data and save the values we care about to state.
    const auth0User = await client.getUser(AUTH0_OPTIONS);
    setUser({
      email: auth0User.email,
      firstName: auth0User.given_name,
      lastName: auth0User.family_name,
      name: auth0User.name,
      picture: auth0User.picture,
    });

    // Update state to show that we are now authenticated.
    setIsAuthenticated(true);
  };

  const origin =
    typeof window !== 'undefined'
      ? window.location.origin
      : process.env.TERRA_DOMAIN;

  // Initialize Auth0. This is called from useEffect below on initial mount.
  const initAuth0 = async () => {
    // Create Auth0 client and save to state.
    const client = await createAuth0Client({
      domain: process.env.AUTH0_DOMAIN || '',
      client_id: process.env.AUTH0_CLIENTID || '',
      redirect_uri: `${origin}`,
      ...AUTH0_OPTIONS,
    });
    setAuth0(client);

    // If we are already authenticated, update state with user data.
    const auth0IsAuthenticated = await client.isAuthenticated();
    if (auth0IsAuthenticated) await setUserData(client);

    // We are now done loading auth.
    setLoading(false);
  };

  // This runs once when the component mounts for the first time.
  useEffect(() => {
    initAuth0();
  }, []);

  // Function to launch the popup and log the user in.
  const login = async () => {
    // Ignore this if the Auth0 client is not initialized yet.
    if (!auth0Client) return;

    // Mark that the popup is open.
    setPopupOpen(true);

    // Launch the popup to log the user in.
    try {
      await auth0Client.loginWithPopup({ connection: 'google-oauth2' });
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    // Now that we're logged in, fetch user data and save it to state.
    await setUserData();
  };

  // Set context object to pass to the provider.
  const context = {
    isAuthenticated,
    user,
    loading,
    popupOpen,
    token,
    login,
    logout: async () => auth0Client?.logout({ returnTo: `${origin}` }),
  };

  if (loading) {
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    );
  }

  // Return the wrapped child elements.
  if (isAuthenticated) {
    // Return the Provider component, wrapping the children nodes.
    return (
      <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
  }

  // By default, prompt the user to authenticate.
  return (
    <>
      <Global />
      <Helmet>
        <title>ICON Admin</title>
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>
      <CenterContent>
        <Button type="button" onClick={login}>
          Log In To Admin
        </Button>
      </CenterContent>
    </>
  );
};

export default AuthProvider;

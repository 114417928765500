import React from 'react';
import styled from 'styled-components';

import * as colors from '@data/colors';

interface CenterContentProps {
  bgColor?: string;
  children: React.ReactChildren | React.ReactChild;
}

const Container = styled.div<CenterContentProps>`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  display: grid;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
`;

export const CenterContent = ({
  bgColor = colors.darkGray,
  children,
}: CenterContentProps) => {
  return <Container bgColor={bgColor}>{children}</Container>;
};

export default CenterContent;

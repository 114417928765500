import { createGlobalStyle } from 'styled-components';

import { cyan, darkGray } from '@data/colors';
import '../icon-font/css/icons.css';
import './tailwind.css';

const Global = createGlobalStyle`
  *:focus {
    outline: 0;
  }

  html,
  body {
    overflow-x: hidden;
    max-width: 100%;
  }

  body {
    background: ${(props) => (props.theme === 'light' ? 'white' : darkGray)};
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
  }
  
  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h4 {
    font-weight: 300;
  }

  .font-display {
    font-family: 'Montserrat', sans-serif;
  }

  .font-body {
    font-family: 'Roboto', sans-serif;
  }

  p {
    line-height: 1.5em;
  }

  /* Remove default styling for links. We only really want styling
    like this in content sections, and those styles are defined in
    <Content />. For all other uses, we want to start with a blank
    slate, which this provides. */
  a {
    color: inherit;
    text-decoration: none;
  }

  p a {
    border-bottom: 1px solid ${cyan};
    color: ${cyan};
    transition: 0.5s;
    &:hover {
      border-bottom: 1px solid darlen(${cyan}, 30%);
      color: darken(${cyan}, 30%);
    }
  }

  /**
  * Remove global styling on buttons for ease of use in UI elements.
  */
  button {
    width: auto;
    background: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    border: none;
    margin: 0;
    padding: 0;
    line-height: inherit;
    text-align: left;
  }

  /* Remove inheritance of text transform in Edge, Firefox, IE. */
  button,
  select {
    text-transform: none;
  }

  /* Remove the inner border and padding in Firefox. */
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /* Restore the focus styles unset by the previous rule. */
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /* Correct the padding in Firefox. */
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  pre {
    /* Correct inheritance/scaling of font size in all browsers. */
    font-family: monospace, monospace;
    /* Correct the odd em font sizing in all browsers. */
    font-size: 1em;
  }

  /* Prevent superscripts and subscripts from affecting line-height. */
  sup,
  sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }
  sub {
    top: 0.4em;
  }
  iframe {
    border: 0px;
  }
`;

export default Global;

import React from 'react';
import styled from 'styled-components';

import Spinner from '@atoms/spinner';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Loading = () => (
  <Container>
    <Spinner color="#aaa" size={50} />
  </Container>
);

export default Loading;

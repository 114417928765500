export const white = '#ffffff';
export const black = '#000000';

export const gray = '#f1f1f1';
export const midGray = '#333333';
export const darkGray = '#252525';

export const cyan = '#00CBE6';
export const cyanDark = '#00CBE6';

export const purple = '#BB93FF';
export const purpleDark = '#8E54F3';

export const red = '#FF3642';
export const redDark = '#E83647';

export const green = '#00D99B';
export const greenDark = '#00C48B';

export const pink = '#FF6294';
export const pinkDark = '#F15885';

export const yellow = '#FFBC00';
export const yellowDark = '#EBA900';

export const orange = '#FF5E49';
export const orangeDark = '#F15542';

export const gradient = [
  { stop: 0, color: '#73A7EF' },
  { stop: 0.4, color: '#A38EDB' },
  { stop: 1, color: '#EA63C3' },
];

// Left/right edges to frame a content page when it expands beyond maxWidth.
export const backgroundEdges = '#000';

import React from 'react';

export const Spinner = ({
  size = 38,
  color = '#fff',
}: {
  size?: number;
  color?: string;
}) => (
  <svg height={size} width={size} stroke={color} viewBox="0 0 38 38">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

export default Spinner;
